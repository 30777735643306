@import "../imports/own_frontend_settings/breakpoint";

.sp_titleBarTitleWhiteBackground, .sp_titleBarTitleOffset {
  background-color: white;
}

.sp_titleBarTitle {
  padding-top: 8px;
  padding-left: 16px;
  padding-bottom: 8px;
  margin-top: 0;
}

.sp_titleBarTitleOffsetGridContainer {
  display: flex;

  .sp_titleBarTitleOffset {
    flex-grow: 1;
  }
}

.pl_grid-lane>.pl_grid-container>.sp_titleBarTitleOffsetGridContainer {
  padding-top: 0;
}

@media not (min-width: $breakpoint-l) {
  .sp_titleBarTitleOffsetGridContainer {
    display: none;
  }
}
