@import "@otto-ec/global-frontend-settings/all";
@import "../imports/own_frontend_settings/grid";
@import "../imports/own_frontend_settings/breakpoint";

.contentFullWidth {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.mo-frame {
  .contentWithSidebar {
    width: 100%;
  }
}

.contentWithSidebar {
    width: 100%;
    display: flex;
    flex-direction: column;
    @media (min-width: $breakpoint-l) {
        width: calc(100% / 12 * (12 - $left-navigation-cols));
    }
}
